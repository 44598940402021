h2.title{text-align: center;}
body, html, #root, .App {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

/* Flex-Container für das Hauptlayout */
.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  color: white;
  justify-content: space-between;
  padding: 5px;
  font-size: 12px;
  background-color: black;
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0,10,0,.05);
  --tw-shadow-colored: 0 1px 2px 0 5;
}
.footer {
  position: fixed;
  bottom: 0;
  font-size: 12px;
  width: 100%;
  text-align: left;
  padding: 10px;
  color: #a3a0a0;
  background-color: black;
}
.icon {
  margin-right: 2px;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
img.navbar-brand {
  float: left;
  height: 15px;
  padding-right:10px;
}
.btn-sm{
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn {
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.username{
  font-size: 12px;
}
.navbar-version {
  font-size: .65em;
  color: #ffffff8c;
}

.user-profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: brown;
  color: #aaa;
  font-size: 8px;
  border-radius: 50%;
  font-weight: 800;
}

